import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

export const AdvertisementPin = ({ className, variants }) => (
  <AdvertisementPinSVG
    width="513"
    height="846"
    viewBox="0 0 513 846"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    variants={variants}
  >
    <g filter="url(#filter0_f)">
      <ellipse
        cx="256"
        cy="722.5"
        rx="179"
        ry="49.5"
        fill="black"
        fillOpacity="0.2"
      />
    </g>
    <g filter="url(#filter1_d)">
      <path
        d="M4 46C4 20.5949 24.5949 0 50 0H463C488.405 0 509 20.5949 509 46V561.133C509 586.538 488.405 607.133 463 607.133H344.701C342.871 607.133 341.141 607.968 340.002 609.402L249.676 723.147L172.981 609.771C171.865 608.121 170.003 607.133 168.011 607.133H50C24.5949 607.133 4 586.538 4 561.133V46Z"
        fill="#0071EB"
      />
      <path
        d="M50 5.5H463C485.368 5.5 503.5 23.6325 503.5 46V561.133C503.5 583.501 485.368 601.633 463 601.633H344.701C341.193 601.633 337.877 603.234 335.695 605.981L250.031 713.855L177.536 606.689C175.397 603.527 171.828 601.633 168.011 601.633H50C27.6325 601.633 9.5 583.501 9.5 561.133V46C9.5 23.6325 27.6325 5.5 50 5.5Z"
        stroke="white"
        strokeWidth="11"
      />
    </g>
    <g filter="url(#filter2_d)">
      <path
        d="M338.295 316.91C337.81 324.189 334.898 330.983 330.045 336.807C322.281 345.542 311.119 350.394 299.473 349.424C292.193 348.939 285.399 346.027 279.576 341.174C270.841 333.409 265.988 322.248 266.958 310.601C267.444 303.322 270.355 296.528 275.208 290.704C282.973 281.969 294.134 277.116 305.781 278.087C313.06 278.572 319.854 281.484 325.678 286.337C334.413 294.101 339.266 305.263 338.295 316.91Z"
        fill="#74E2BE"
      />
      <path
        d="M235.417 155.312C234.932 162.591 232.02 169.385 227.167 175.208C219.403 183.943 208.241 188.797 196.594 187.826C189.315 187.341 182.521 184.429 176.697 179.576C167.962 171.811 163.109 160.65 164.08 149.003C164.565 141.724 167.477 134.93 172.33 129.106C180.094 120.371 191.256 115.518 202.903 116.489C210.182 116.974 216.976 119.886 222.8 124.739C231.535 132.503 235.902 143.665 235.417 155.312Z"
        fill="white"
      />
      <path
        d="M325.679 124.254L359.649 158.224C360.62 159.195 360.62 160.651 359.649 161.621L180.093 341.177C179.123 342.148 177.667 342.148 176.696 341.177L142.726 307.207C141.756 306.236 141.756 304.78 142.726 303.81L322.282 124.254C323.738 123.769 324.708 123.769 325.679 124.254Z"
        fill="#FF6696"
      />
    </g>
    <path
      d="M190.307 460.712V469.501H178.375V505.746H167.405V469.501H155.473V460.712H190.307Z"
      fill="white"
    />
    <path
      d="M201.329 466.229C199.405 466.229 197.822 465.673 196.582 464.561C195.385 463.407 194.786 461.995 194.786 460.327C194.786 458.617 195.385 457.205 196.582 456.093C197.822 454.939 199.405 454.361 201.329 454.361C203.211 454.361 204.751 454.939 205.948 456.093C207.188 457.205 207.809 458.617 207.809 460.327C207.809 461.995 207.188 463.407 205.948 464.561C204.751 465.673 203.211 466.229 201.329 466.229ZM206.782 469.95V505.746H195.812V469.95H206.782Z"
      fill="white"
    />
    <path d="M225.702 458.275V505.746H214.732V458.275H225.702Z" fill="white" />
    <path
      d="M244.621 475.018C245.647 473.35 247.123 472.003 249.047 470.976C250.972 469.95 253.174 469.437 255.655 469.437C258.606 469.437 261.279 470.185 263.674 471.682C266.069 473.179 267.95 475.317 269.319 478.097C270.73 480.877 271.436 484.106 271.436 487.784C271.436 491.462 270.73 494.712 269.319 497.535C267.95 500.315 266.069 502.474 263.674 504.014C261.279 505.511 258.606 506.259 255.655 506.259C253.132 506.259 250.929 505.767 249.047 504.784C247.166 503.757 245.69 502.41 244.621 500.742V505.746H233.651V458.275H244.621V475.018ZM260.274 487.784C260.274 485.047 259.504 482.908 257.964 481.369C256.468 479.786 254.607 478.995 252.383 478.995C250.202 478.995 248.342 479.786 246.802 481.369C245.305 482.951 244.557 485.111 244.557 487.848C244.557 490.585 245.305 492.745 246.802 494.327C248.342 495.91 250.202 496.701 252.383 496.701C254.564 496.701 256.425 495.91 257.964 494.327C259.504 492.702 260.274 490.521 260.274 487.784Z"
      fill="white"
    />
    <path
      d="M312.538 469.95V505.746H301.568V500.871C300.456 502.453 298.938 503.736 297.014 504.72C295.132 505.66 293.036 506.131 290.727 506.131C287.99 506.131 285.573 505.532 283.478 504.335C281.382 503.094 279.757 501.32 278.602 499.01C277.448 496.701 276.87 493.985 276.87 490.863V469.95H287.776V489.388C287.776 491.783 288.396 493.643 289.636 494.969C290.876 496.294 292.544 496.957 294.64 496.957C296.778 496.957 298.468 496.294 299.708 494.969C300.948 493.643 301.568 491.783 301.568 489.388V469.95H312.538Z"
      fill="white"
    />
    <path
      d="M318.236 487.784C318.236 484.106 318.921 480.877 320.289 478.097C321.701 475.317 323.604 473.179 325.999 471.682C328.394 470.185 331.067 469.437 334.017 469.437C336.37 469.437 338.508 469.929 340.433 470.912C342.4 471.896 343.939 473.222 345.051 474.89V458.275H356.021V505.746H345.051V500.614C344.025 502.325 342.55 503.693 340.625 504.72C338.743 505.746 336.541 506.259 334.017 506.259C331.067 506.259 328.394 505.511 325.999 504.014C323.604 502.474 321.701 500.315 320.289 497.535C318.921 494.712 318.236 491.462 318.236 487.784ZM345.051 487.848C345.051 485.111 344.282 482.951 342.742 481.369C341.245 479.786 339.406 478.995 337.225 478.995C335.044 478.995 333.184 479.786 331.644 481.369C330.147 482.908 329.399 485.047 329.399 487.784C329.399 490.521 330.147 492.702 331.644 494.327C333.184 495.91 335.044 496.701 337.225 496.701C339.406 496.701 341.245 495.91 342.742 494.327C344.282 492.745 345.051 490.585 345.051 487.848Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_f"
        x="3"
        y="599"
        width="506"
        height="247"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur stdDeviation="37" result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="filter1_d"
        x="0"
        y="0"
        width="513"
        height="731.147"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d"
        x="87.9985"
        y="66.3665"
        width="326.378"
        height="341.18"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="27" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </AdvertisementPinSVG>
);

export const AdvertisementPinSVG = styled(motion.svg)`
  z-index: 120;
`;
