import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

export const BlueDot = ({ size, className, variants }) => (
  <BlueDotSVG
    width={size}
    height={size}
    viewBox="0 0 74 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    variants={variants}
  >
    <circle
      cx="37"
      cy="37"
      r="28"
      fill="#0071EB"
      stroke="#CFDEED"
      strokeWidth="17"
    />
  </BlueDotSVG>
);

export const BlueDotSVG = styled(motion.svg)`
  z-index: 120;
`;
