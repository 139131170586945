import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import Roads from '../assets/images/Roads_2.png';

export default function BackgroundMap({
  children,
  color,
  className,
  variants,
  imageColor,
  imageStyle,
  image,
  imageHeight,
  overlay,
}) {
  return (
    <MapContainer
      className={className}
      variants={variants}
      imageHeight={imageHeight}
      initial="hidden"
      animate="show"
    >
      {image && (
        <Image
          imageStyle={imageStyle}
          src={Roads}
          id="roads"
          alt=""
          imageColor={imageColor}
        />
      )}
      {overlay && <Overlay color={color} imageHeight={imageHeight} />}
      {children}
    </MapContainer>
  );
}

export const Image = styled.img`
  object-fit: ${(props) => (props.imageStyle ? props.imageStyle : 'cover')};
  width: 100%;
  max-width: 100vw;
  height: 100%;
  background-color: ${(props) =>
    props.imageColor ? props.imageColor : 'none'};

  @media (min-width: 1080px) {
    width: 100%;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: ${(props) =>
    props.imageHeight ? `${props.imageHeight}px` : '600px'};
  background: ${(props) =>
    props.color
      ? `linear-gradient(0deg,rgba(239, 242, 249, 0) 0%, ${props.color} 95%)`
      : `linear-gradient(0deg,rgba(239, 242, 249, 0) 0%, var(--seaGreen) 105%)`};

  @media (min-width: 1080px) {
    max-height: 900px;
    max-width: var(--maxGridWidth);
    margin: 0 auto;
  }
`;

export const MapContainer = styled(motion.div)`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-template-rows: repeat(14, 1fr);
  position: relative;
  overflow: hidden;
  grid-gap: 1rem;
  max-width: 100vw;
  z-index: 95;
  max-height: ${(props) =>
    props.imageHeight ? `${props.imageHeight}px` : '600px'};

  ${Image} {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }

  ${Overlay} {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }

  @media (min-width: 1080px) {
    grid-template-columns: repeat(14, 1fr);
    overflow: unset;
    margin-top: -8rem;
    max-width: var(--maxGridWidth);
    margin: -8rem auto 0 auto;
  }
`;
