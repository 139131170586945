import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

export const RedDotBigRedCirle = ({ size, className, variants }) => (
  <RedDotBigRedCirleSVG
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 42 42"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    variants={variants}
  >
    <circle
      cx="21"
      cy="21"
      r="17"
      fill="#FF6696"
      stroke="#FFE1EB"
      strokeWidth="7"
    />
  </RedDotBigRedCirleSVG>
);

export const RedDotBigRedCirleSVG = styled(motion.svg)`
  z-index: 120;
`;
